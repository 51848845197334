<template>
  <div>
    <b-container fluid>
      <b-row>
        <b-col>
          <h5 style="font-size: 1rem">
            {{ nomUser }}, seleccione la fecha que desea agendar:
          </h5>
        </b-col>
      </b-row>
      <b-row class="mb-3 mt-2">
        <b-col class="text-center">
          <b-calendar block v-model="tmpDateSelected" @context="onContext" locale="es" :min="minDate" :start-weekday="1"
            @selected="selectDate" :date-disabled-fn="dateDisabled" selected-variant="primary" today-variant="primary"
            small id="calendar" dark="true" :date-info-fn="dateClass"></b-calendar>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-container>
            <b-row>
              <b-col col-sm="12" class="mt-2 text-center">
                <b-button :pressed="true" type="button" variant="primary" @click="goStepPersonalInfo">
                  <!-- <div class="h5 mb-2 mt-1"> -->
                  <b-icon icon="person-fill" aria-hidden="true" class="mr-2"></b-icon>
                  <!-- </div> -->
                  <p class="m-0" style="font-size: 0.8rem">Datos Personales</p>
                </b-button>
              </b-col>
              <b-col col-sm="12" class="mt-2 text-center">
                <b-button :pressed="true" type="button" variant="primary" @click="goStepSchedule">
                  <!-- <div class="h5 mb-2 mt-1"> -->
                  <b-icon icon="clock-history" aria-hidden="true" class="mr-2"></b-icon>
                  <!-- </div> -->
                  <p class="m-0" style="font-size: 0.8rem">
                    Seleccionar Horario
                  </p>
                </b-button>
              </b-col>
            </b-row>
          </b-container>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import moment from "moment";
import { mapActions, mapState } from "vuex";
var moment_tz = require("moment-timezone");

export default {
  name: "StepDate",
  components: {},
  data() {
    return {
      tmpDateSelected: null,
      context: null,
      minDate: new Date(moment()), //new Date(moment().add(1, "days")),
    };
  },
  computed: {
    ...mapState("reserves", ["dateSelected", "idPlaceSelected"]),
    ...mapState("users", ["userData"]),
    nomUser() {
      return this.userData.name + " " + this.userData.lastname;
    },

  },
  mounted() {
    //Verificamos si tenemos datos del usuario, si no se tienen se va al inicio
    if (this.userData.document == "") {
      this.$router.replace("/");
    }

    //Asignamos fecha seleccionada si la hay
    this.tmpDateSelected = this.dateSelected;
  },
  methods: {
    ...mapActions("reserves", ["setReserveDate"]),
    onContext(ctx) {
      this.context = ctx;
    },
    goStepSchedule() {
      if (this.tmpDateSelected == "") {
        this.$toastr.e("Debe seleccionar una fecha.", "Fecha de Reserva");
        return;
      }

      //Asignamos en memoria la fecha seleccionada
      this.setReserveDate(this.tmpDateSelected);

      //Vamos al siguiente paso
      this.$router.push("StepSchedule");
    },
    goStepPersonalInfo() {
      //Vamos al paso anterior
      this.$router.go(-1);
    },
    selectDate(ctx) {
      this.tmpDateSelected = ctx;
      this.goStepSchedule();
    },
    dateDisabled(ymd, date) {
      // Disable weekends (Sunday = `0`)
      const weekday = date.getDay();
      const day = date.getDate();
      let activar = true



      //para desactivar fechas por sede

      // YgvgNadL1LQBshrmf9O4 -> Principal
      if (this.idPlaceSelected == 'YgvgNadL1LQBshrmf9O4' && moment(date).format('YYYY-MM-DD') == '2021-11-16') {
        activar = false;
      }
      if (this.idPlaceSelected == 'YgvgNadL1LQBshrmf9O4' && moment(date).format('YYYY-MM-DD') == '2021-11-17') {
        activar = false;
      }
      if (this.idPlaceSelected == 'YgvgNadL1LQBshrmf9O4' && moment(date).format('YYYY-MM-DD') == '2021-11-18') {
        activar = false;
      }
      if (this.idPlaceSelected == 'R9k5OaWQpCcVcJ2xryLZ' && moment(date).format('YYYY-MM-DD') > '2023-09-09') {
        activar = false;
      }

      //CfXJMEnvQKB3QHKDXafd -> Barrancabermeja
      if (this.idPlaceSelected == 'CfXJMEnvQKB3QHKDXafd') {
        //Se desactiva el sabado para barranca
        activar = weekday == 6 ? false : true
      }

      if (moment_tz(date).tz("America/Bogota") > moment_tz().tz("America/Bogota").add(1, 'months')) {
        activar = false
      }


      return weekday === 0 || this.isFestive(date) || !activar;
    },
    dateClass(ymd, date) {
      return !this.dateDisabled(ymd, date) ? 'table-info' : ''
    },
    isFestive(date) {
      let festivesArray = [
        "2022-03-21",
        "2022-04-14",
        "2022-04-15",
        "2022-05-30",
        "2022-06-20",
        "2022-06-27",
        "2022-07-04",
        "2022-07-20",
        "2022-08-15",
        "2022-10-17",
        "2022-11-07",
        "2022-11-14",
        "2022-12-08",
        "2023-01-09",
        "2023-03-20",
        "2023-04-06",
        "2023-04-07",
        "2023-05-01",
        "2023-05-22",
        "2023-06-12",
        "2023-06-19",
        "2023-07-03",
        "2023-07-20",
        "2023-08-07",
        "2023-08-21",
        "2023-10-16",
        "2023-11-06",
        "2023-11-13",
        "2023-12-08",
        "2023-12-25",
        "2024-01-08",
        "2024-03-25",
        "2024-03-28",
        "2024-03-29",
        "2024-03-30",
        "2024-05-01",
        "2024-05-13",
        "2024-06-03",
        "2024-06-10",
        "2024-07-01",
        "2024-07-20",
        "2024-08-07",
        "2024-08-19",
        "2024-10-14",
        "2024-11-04",
        "2024-11-11",
        "2024-12-08",
        "2024-12-25",
        "2025-01-01",
        "2025-01-06",
        "2025-03-24",
        "2025-04-17",
        "2025-04-18",
        "2025-05-01",
        "2025-06-02",
        "2025-06-23",
        "2025-06-30",
        "2025-08-07",
        "2025-08-18",
        "2025-10-13",
        "2025-11-03",
        "2025-11-17",
        "2025-12-08",
        "2025-12-25"
      ];

      return festivesArray.indexOf(moment(date).format("YYYY-MM-DD")) >= 0
        ? true
        : false;
    },
  },
};
</script>

<style lang="scss"></style>
